import React from "react";
import Section from "./Section/Section";

const Pages = ({ pages, infoboxes }) => {
  if (pages == null) {
    return;
  }
  return (
    <>
      {pages.map((page, index) => {
        return <Section key={index} page={page} infoboxes={infoboxes} />;
      })}
    </>
  );
};

export default Pages;
