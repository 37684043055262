import React from "react";
import SideDrawer from "./SideDrawer/SideDrawer";
const Section = ({ page, infoboxes }) => {
  const heroMedia = (thisPage) => {
    if (thisPage?.acf.iframe) {
      return (
        <iframe
          src={thisPage?.acf.iframe}
          title={thisPage?.title.rendered}
        ></iframe>
      );
    } else if (thisPage.acf.gallery && thisPage.acf.gallery.length > 0) {
      const img = (
        <img
          src={thisPage.acf.gallery[0].full_image_url}
          alt={thisPage.acf.gallery[0].alt_text}
          width="100%"
        />
      );
      if (thisPage.acf.gallery[0].caption != "")
        return (
          <a
            className="w-1/2"
            href={thisPage.acf.gallery[0].caption}
            target="_blank"
          >
            {img}
          </a>
        );
      return <div className="w-1/2">{img}</div>;
    }
  };

  return (
    <section
      id={page.slug}
      className="featurettes overlay bg-fixed"
      data-stellar-background-ratio="0.8"
    >
      <div className="hero bg-base-200">
        <div className="hero-content flex-col lg:flex-row my-16 mx-8">
          {heroMedia(page)}
          <div className="ml-5 w-1/2">
            <h1 className="text-5xl font-bold">{page?.title.rendered}</h1>
            <p
              className="py-6"
              dangerouslySetInnerHTML={{ __html: page?.content.rendered }}
            />
          </div>
        </div>
      </div>
      {page.acf.category != undefined && page.acf.category != "None" ? (
        <SideDrawer category={page.acf.category} boxes={infoboxes} />
      ) : (
        <></>
      )}
    </section>
  );
};

export default Section;
