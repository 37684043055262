import React, { Component } from "react";
import { TfiNewWindow } from "react-icons/tfi";
import "./TopNav.css";

export class TopNav extends Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <div
          id="TopNav"
          className="jumbotron d-flex align-items-center boon-gl level-1"
        >
          <div className="container text-center">
            <h1 id="TitleBox" className="display-1 boon-gl level-15">
              {process.env.REACT_APP_TITLE}
            </h1>
          </div>
          <div class="inline-flex absolute top-10 left-10 z-30">
            <a
              href="https://wp.boonifications.com/wp-content/uploads/2024/11/DavidBoon-CV-Web-Developer.pdf"
              download="DavidBoon-CV-Web-Developer.pdf"
              target="_blank"
              className="cursor-pointer bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <TfiNewWindow className="mb-1 mr-1 text-lg" />
              <span>Web Dev CV</span>
            </a>
            <a
              href="https://wp.boonifications.com/wp-content/uploads/2024/11/DavidBoon-CV-Unity.pdf"
              download="DavidBoon-CV-Unity.pdf"
              target="_blank"
              className="cursor-pointer ml-5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <TfiNewWindow className="mb-1 mr-1 text-lg" />
              <span>Unity CV</span>
            </a>
          </div>
          <div className="rectangle-2"></div>
          <div className="circle-1"></div>
          <div className="circle-2"></div>
          <div className="circle-3"></div>
          <div className="triangle triangle-1"></div>
          <div className="triangle triangle-2"></div>
          <div className="triangle triangle-3"></div>
          <div className="triangle triangle-4"></div>
        </div>
      </div>
    );
  }
}
export default TopNav;
