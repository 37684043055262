import React, { Component } from "react";
import axios from "axios";
import InfoBoxes from "./InfoBoxes";
import TopNav from "./TopNav/TopNav";
import Pages from "./Pages/Pages";

export class Content extends Component {
  state = {
    infoboxes: [],
    pages: [],
    skills: [],
    isLoaded: false,
  };
  async FetchData() {
    await axios
      .get(process.env.REACT_APP_WPURL + "infoboxes/?per_page=100")
      .then((res) =>
        this.setState({
          infoboxes: res.data,
        })
      )
      .catch((err) => console.log(err));
    await axios
      .get(process.env.REACT_APP_WPURL + "pages")
      .then((res) =>
        this.setState({
          pages: res.data,
        })
      )
      .catch((err) => console.log(err));
    await axios
      .get(process.env.REACT_APP_WPURL + "skills")
      .then((res) =>
        this.setState({
          skills: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }
  componentDidMount() {
    this.FetchData();
  }
  render() {
    const { infoboxes, pages } = this.state;
    return (
      <div className="content">
        <InfoBoxes category={"Shady_Header"} boxes={infoboxes} />

        <TopNav infoboxes={infoboxes} />
        <Pages
          pages={this.state.pages.sort(
            (a, b) => parseInt(a.acf.order) - parseInt(b.acf.order)
          )}
          infoboxes={infoboxes}
        />
      </div>
    );
  }
}

export default Content;
