import React, { useState, useEffect } from "react";
import InfoGallery from "../../../InfoGallery";
import { Icon } from "@iconify/react";

const SideDrawer = ({ category, boxes }) => {
  const [modalIndex, setModalIndex] = useState(-1);
  const [filteredBoxes, setFilteredBoxes] = useState(null);
  const [modalId, setModalId] = useState("");
  const handleShow = (index) => {
    setModalIndex(index);

    document.getElementById(modalId)?.showModal();
    document.getElementById("check-" + modalId).checked = false;
  };

  const handleNext = () => {
    const newIndex =
      modalIndex == filteredBoxes.length - 1 ? 0 : modalIndex + 1;
    setModalIndex(newIndex);
  };

  const handlePrevious = () => {
    const newIndex =
      modalIndex == 0 ? filteredBoxes.length - 1 : modalIndex - 1;
    setModalIndex(newIndex);
  };

  const modalBox = () => {
    return modalIndex < filteredBoxes.length ? filteredBoxes[modalIndex] : null;
  };
  useEffect(() => {
    if (boxes == null) return;
    setFilteredBoxes(boxes.filter((item) => item.acf.category === category));
    setModalId("info" + category);
  }, [boxes]);

  if (filteredBoxes == null) return;
  return (
    <div
      id={"info-" + category}
      aria-expanded="true"
      className="drawer drawer-end"
    >
      <input
        id={"check-" + modalId}
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-content">
        {/* {content} */}
        <dialog id={modalId} className="modal">
          <div className="modal-box">
            <div className="join">
              {filteredBoxes.map((box, index) => (
                <button
                  onClick={() => handleShow(index)}
                  className={
                    "join-item btn " +
                    (index === modalIndex ? "btn-active" : "")
                  }
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <h3 className="font-bold text-lg">{modalBox()?.title?.rendered}</h3>
            <p
              className="py-4"
              dangerouslySetInnerHTML={{
                __html: modalBox()?.content?.rendered,
              }}
            />
            <InfoGallery gallery={modalBox()?.acf?.gallery} />
            <div className="modal-action">
              <button
                className="btn"
                onClick={handlePrevious}
                disabled={modalIndex <= 0}
              >
                <Icon icon="ooui:next-rtl" className="next-box" /> Previous
              </button>

              <button
                className="btn"
                onClick={handleNext}
                disabled={modalIndex >= filteredBoxes.length - 1}
              >
                Next <Icon icon="ooui:next-ltr" className="prev-box" />
              </button>

              <form method="dialog">
                <button className="btn">Close</button>
              </form>
            </div>
          </div>
        </dialog>
        <button className="info-loading btn">
          <span className="loading loading-spinner"></span>
          loading
        </button>
        <label
          htmlFor={"check-" + modalId}
          className="info-toggle drawer-button btn"
        >
          <span className="loading loading-ring loading-lg"></span>
          {category.replace(/_/g, " ")}
        </label>
      </div>
      <div className="drawer-side">
        <ul className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
          <label htmlFor={"check-" + modalId} className="drawer-button btn">
            {category.replace(/_/g, " ")}
            <Icon
              icon="mingcute:arrow-to-right-line"
              width="1.2rem"
              height="1.2rem"
              style={{ color: "#fff" }}
            />
          </label>
          {filteredBoxes.map((box, index) => (
            <li key={box.id}>
              <a id={box.id} onClick={() => handleShow(index)}>
                {box.title.rendered}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideDrawer;
